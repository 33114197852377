#footer-basic-style {
    background-color: #F2E5BD;
    color: black;
    text-align: left;
    font-size: 14px;
    overflow: hidden;
    font-family: 'Times New Roman', serif;
    background: linear-gradient(-45deg, #ee7752, #F2E5BD, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 23s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

#logo-container {
    background-color: #ffffff;
    border-radius: 50%;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}