.custom-select {
    border: none;
}

select::-ms-expand {
    display: none;
}

/* Rimuovi la freccia del menu a discesa - Altri browser */
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

/* Stile aggiuntivo per il bordo del menu a discesa (se necessario) */
select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}